import React from "react";
import styled from "styled-components";
import TitleComponent from "../../components/title-component/TitleComponent";
import ProgramCard from "./section/ProgramCard";
import Introduction from "./section/Introduction";

const PageContent = () => {
  return (
    <Wrapper>
      <TitleComponent
        pageMetaName="Proposal Reviews"
        className="page-heading"
        pageName="An Insurance Policy for Your Funding Packages"
      />
      <Introduction />
      <ProgramCard id={1} programName={"Concept Note Evaluation"}>
        <div className="what-we-do-header">Concept Note Evaluation</div>
        <div className="para1">
          <strong style={{ fontWeight: 600 }}>
            Less than 40% of GCF Concept Notes are approved{" "}
          </strong>
          and less than 20% of those approved are from African and small island
          states. This failure to unlock climate finance starts with Concept
          Notes that miss the mark. Let us help you to full proof your concepts.
          Here’s what we’ll do:
        </div>
        <div className="para2">
          <ul>
            <li>
              Assess your CN against all known funder criteria and provide clear
              recommendations of correct problem areas.
            </li>
            <li>
              Assess the strength of your climate rationale and theory of
              change.
            </li>
            <li>
              Provide a full report that highlights all issues and
              recommendations.
            </li>
            <li>
              Add expert reviewer support for an additional fee. (Contact us for
              fee schedule)
            </li>
          </ul>
        </div>
      </ProgramCard>
      <ProgramCard id={2} programName={"Funding Proposal Evaluation"}>
        <div className="what-we-do-header">Funding Proposal Evaluation</div>
        <div className="para1">
          Don't risk being denied this urgent funding because of proposal
          omissions, underdeveloped narratives, and more generally, not
          addressing all six GCF criteria fully. Let us help you to fully assess
          your proposals and provide feedback so it achieves “High” scores
          across all criteria. Here’s what we’ll do:{" "}
        </div>
        <div className="para2">
          <ul>
            <li>
              Assess your proposals against the ICS scoring criteria (Annex 1)
              and provide recommendations to correct all problem areas.
            </li>
            <li>
              Provide you a list of questions that reviewers may ask about the
              project so you can address those in advance. 
            </li>
            <li>
              Provide a full report that highlights all issues and
              recommendations.
            </li>{" "}
            <li>
              Add expert reviewer support for an additional fee. (Contact us for
              fee schedule)
            </li>
          </ul>
        </div>
      </ProgramCard>
      <ProgramCard id={3} programName={"Adaptation Fund Proposal Review"}>
        <div className="what-we-do-header">Adaptation Fund Proposal Review</div>
        <div className="para1">
          Let us help you to fully assess your Adaptation Fund (AF) proposals
          against all known criteria and more. We’ll help you navigate the
          complex application process and ensure your proposals meet all AF
          criteria. Here’s what we’ll do:
        </div>
        <div className="para2">
          <ul>
            <li>
              A comprehensive assessment of your CN/Proposal against all
              criteria, with actionable recommendations for improvement.
            </li>
            <li>
              Rigorous review of your project's climate rationale and theory of
              change.
            </li>
            <li>
              Provide a full report that highlights all issues and
              recommendations.
            </li>
            <li>
              Add expert reviewer support for an additional fee. (Contact us for
              fee schedule)
            </li>
          </ul>
        </div>
      </ProgramCard>
      <ProgramCard id={4} programName={"VCS Proposal Review"}>
        <div className="what-we-do-header">VCS Proposal Review</div>
        <div className="para1">
          Projects striving for VCS certification must be prepared to be
          rigorously evaluated.{" "}
          <strong>
            Significantly reduce the chances that your project will be rejected!
          </strong>{" "}
          Our AI-enabled software can precisely review all aspects of your
          submission and red flag problem areas. Here’s what we’ll do:
        </div>
        <div className="para2">
          <ul>
            <li>
              Assess your proposal against all known VCS standard criteria.{" "}
            </li>
            <li>Provide clear recommendations to correct problems.</li>
            <li>
              Add expert reviewer support for an additional fee. (Contact us for
              fee schedule)
            </li>
          </ul>
        </div>
      </ProgramCard>
    </Wrapper>
  );
};

export default PageContent;

const Wrapper = styled.div`
  .what-we-do-header {
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  ul > li {
    padding: 10px 0;
  }

  ul {
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  .page-heading {
    //max-width: 50%;
    //@media (max-width: 768px) {
    //  max-width: unset;
    //}
  }

  .para1,
  .para2 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
    max-width: 700px;
  }
`;
