import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import HowToFundImage from "assets/images/htf-for-aes.jpg";

const HowToFundWrapper = styled.div`
  padding: 50px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

const Heading = styled.h2`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  font-size: 20px;
  color: #2e2e2e;
  line-height: 1.6;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 650px;
    height: auto;
    border-radius: 8px;
  }
`;

const List = styled.ul`
  font-size: 20px;

  li {
    padding: 20px 0;
  }
`;

const HowToFund = ({ image }) => {
  return (
    <HowToFundWrapper>
      <Row gutter={[32, 32]} align="middle">
        {/* Left Column: Text */}
        <Col xs={24} md={14}>
          <Heading>How to Fund</Heading>
          <Paragraph>
            Our Enterprise license offers a significant return on investment
            through streamlined processes and faster project approvals, making a
            compelling case for direct investment. However, we understand budget
            constraints and want to provide some ways to get funded:
          </Paragraph>
          <List>
            <li>
              <strong>Internal Budget Allocation:</strong> Given the software's
              demonstrable contribution to Accredited Entity effectiveness,
              allocating funds from your existing budget could be a strategic
              investment.
            </li>
            <li>
              <strong>Partnership:</strong> Collaborate with the National
              Designated Authority / Focal Point to co-fund the license. If they
              benefit from better prepared concepts and proposals, they may be
              willing to co-invest. (Consider the GCF’s Readiness and{" "}
              <a
                style={{ color: "#333", textDecoration: "underline" }}
                href="https://www.greenclimate.fund/readiness/dae-window"
                target="_blank"
              >
                DAE Window
              </a>{" "}
              programs for grant funding).
            </li>
            <li>
              <strong>National Climate Fund:</strong> Explore whether your
              country's national climate fund (if applicable) might support the
              software. These funds often have more flexible funding criteria.
            </li>
          </List>
        </Col>

        {/* Right Column: Image */}
        <Col xs={24} md={10}>
          <ImageWrapper>
            <img src={HowToFundImage} alt="How to Fund" />
          </ImageWrapper>
        </Col>
      </Row>
    </HowToFundWrapper>
  );
};

export default HowToFund;
