import React from "react";
import styled from "styled-components";
import PartnerRow from "./PartnerRow";
import { Row, Col } from "antd";

import ConceptNote from "assets/images/partner-concept_notes.png";
import FullProposals from "assets/images/partner-full_proposals.png";
import Software from "assets/images/partner-software_subscriptions.png";
import Scenario from "assets/images/partner-1st_year_scenario.jpg";

const PartnerList = () => {
  return (
    <Wrapper>
      <Row
        gutter={{
          xs: 16, // Small devices
          sm: 16, // Tablets
          md: 32, // Medium screens
          lg: 32, // Large screens
          xl: 800, // Extra-large screens
          xxl: 800, // Extra-large screens
        }}
        justify="center"
      >
        {/* Concept Notes */}
        <Col xs={22} sm={20} md={20} lg={23} xl={23} xxl={23}>
          <PartnerRow
            id={1}
            img={
              <img
                className="partner-img"
                src={ConceptNote}
                alt={"Concept Note"}
              />
            }
            programName="Concept Note Evaluation"
          >
            <div className="row-header">
              AI-Assisted Reviews – Concept Notes
            </div>
            <p>
              Most concept notes miss the mark. Help project owners win by
              providing AI-assisted reviews and profit. Here is how we'll work
              together:
            </p>
            <ol>
              <li>Janus assigns you a Concept Note (CN)</li>
              <li>CN Review approach meeting with Janus</li>
              <li>Receive 50% payment</li>
              <li>Complete the review and report</li>
              <li>Review report with client</li>
              <li>Receive remaining 50% payment</li>
            </ol>
            <p>
              <span>Your Take Home Pay:</span>{" "}
              <strong className="thp">$500 - $1,000 USD</strong>
            </p>
          </PartnerRow>
        </Col>

        {/* Full Proposals */}
        <Col xs={22} sm={20} md={20} lg={23} xl={23} xxl={23}>
          <PartnerRow
            id={2}
            img={
              <img
                className="partner-img"
                src={FullProposals}
                alt={"Full Proposal"}
              />
            }
            programName="Full Proposals"
            reverse={true}
          >
            <div className="row-header">
              AI-Assisted Reviews – Full Proposals
            </div>
            <p>
              Most proposals are subject to 6–7 rounds of reviews. Let’s work
              together so that projects sail through donor evaluation processes.
              Here is how we'll work together:
            </p>
            <ol>
              <li>Proposal Review meeting with Janus</li>
              <li>Receive 50% payment</li>
              <li>Complete the review and report</li>
              <li>Review report with client</li>
              <li>Receive remaining 50% payment</li>
            </ol>
            <p>
              <span>Your Take Home Pay:</span>{" "}
              <strong className="thp">$1,500 - TBD</strong>
            </p>
          </PartnerRow>
        </Col>

        {/* Software Subscriptions */}
        <Col xs={22} sm={20} md={20} lg={23} xl={23} xxl={23}>
          <PartnerRow
            id={3}
            img={
              <img
                className="partner-img"
                src={Software}
                style={{ maxWidth: "580px" }}
                alt={"Software Subscriptions"}
              />
            }
            programName="Software Subscriptions"
          >
            <div className="row-header">
              Software Subscriptions/License Sales
            </div>
            <p>
              Climate Finance Copilot revolutionizes climate finance operations.
              Help us showcase its value to AF/CF Accredited Entities and earn
              significant rewards. Here’s how it works:
            </p>
            <ul>
              <li>
                Get started: Create your free account and experience the power
                of Climate Finance Copilot.
              </li>
              <li>
                Spread the word: Introduce the software to your network and
                assist our sales team in closing deals.
              </li>
              <li>
                Share your expertise (optional): Provide virtual training to
                clients.
              </li>
            </ul>
            <p>
              <strong>Your rewards:</strong>
            </p>
            <ul>
              <li>Earn 20% of net license revenue for two years.</li>
              <li>
                Receive $500 for each 4–6 hour virtual workshop you conduct.
              </li>
            </ul>
          </PartnerRow>
        </Col>

        {/* 1st Year Scenario */}
        <Col xs={22} sm={20} md={20} lg={23} xl={23} xxl={23}>
          <PartnerRow
            id={4}
            img={
              <img
                className="partner-img"
                src={Scenario}
                alt={"Plausible 1st Year Scenario"}
              />
            }
            programName="Plausible 1st Year Scenario"
            reverse={true}
          >
            <div className="row-header">Plausible 1st Year Scenario</div>
            <ol className="scenarios">
              <li>
                Average Performer: Nine concept notes + three proposals
                <br />
                Time commitment: 28 days (One month)
                <br />
                Take home pay:{" "}
                <strong className="thp">Approximately $15,000 USD</strong>
              </li>
              <li>
                Pro performer: Twenty-four concept notes + Twelve proposals
                <br />
                Time commitment: 84 days (Three months)
                <br />
                Take home pay:{" "}
                <strong className="thp">Approximately $50,000 USD</strong>
              </li>
            </ol>
          </PartnerRow>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default PartnerList;

const Wrapper = styled.div`
  ul > li {
    padding: 0;
    margin: 0;
  }

  strong.thp {
    font-weight: 600;
  }

  .scenarios > li {
    padding-bottom: 50px;
  }

  ol,
  ul {
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  .row-header {
    color: #000000;
    font-size: 32px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
`;
