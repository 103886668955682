import React from "react";
import styled from "styled-components";
import { Row, Col } from "components/common";

const SectionWrapper = styled.div`
  font-family: "Inter", sans-serif;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding: 20px;
    text-align: left;
  }
`;

const SectionHeading = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
  width: 80%;

  @media (max-width: 768px) {
    width: unset;
    font-size: 36px;
  }
`;

const SectionParagraph = styled.p`
  font-size: 18px;
  color: #2e2e2e;
  line-height: 1.6;
  width: 70%;

  @media (max-width: 768px) {
    width: unset;
  }
`;

const SectionIntro = () => {
  return (
    <SectionWrapper>
      <Row
        gutter={{
          xs: 16, // Small devices
          sm: 16, // Tablets
          md: 32, // Medium screens
          lg: 32, // Large screens
          xl: 800, // Extra-large screens
          xxl: 800, // Extra-large screens
        }}
        justify="center"
      >
        {" "}
        <Col xs={24} sm={22} md={20} lg={22} xl={22} xxl={23}>
          <SectionHeading>
            All You Need To Know About The Partner Program
          </SectionHeading>
          <SectionParagraph>
            The Janus Partner Program unlocks a powerful income opportunity for
            professionals passionate about climate finance. As a select partner,
            you’ll drive meaningful impact in your region while building a
            strong, recurring revenue stream. Our cutting-edge AI-powered
            software empowers you to deliver top-tier results—all on your own
            schedule.
          </SectionParagraph>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default SectionIntro;
