import { Col, CustomButton, Row } from "components/common";
import React from "react";
import styled from "styled-components";
import Option1Icon from "assets/svg/opton-1.svg";
import Option2Icon from "assets/svg/opton-2.svg";
import Option3Icon from "assets/svg/opton-3.svg";
import CapBtnIcon from "assets/svg/new-cap-btn.svg";
import FavIcon from "assets/images/favicon.png";

const OurStory = () => {
  const openPdf = (e) => {
    e.preventDefault();
    const newTab = window.open("/pdf/janus-capability-statement.pdf", "_blank");
    if (newTab) {
      newTab.onload = () => {
        const head = newTab.document.head;
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = FavIcon; // Use the correct path to your Janus favicon
        head.appendChild(link);
      };
    }
  };
  return (
    <Wrapper>
      <Row gutter={[0, 100]}>
        <Col xs={24} lg={24}>
          <Row className="option-row">
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-img-col"
            >
              <img src={Option1Icon} alt="option1" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-col-2"
            >
              <div className="what-we-do-header">Option #1: Subscription: </div>
              <div className="para3">
                Embrace hassle-free software access with our SaaS model.
                Subscribe monthly or annually and enjoy full software
                functionality without worrying about installation, maintenance,
                or updates. This model ensures you're always using the latest
                version with automatic updates and features seamlessly
                integrated. Ideal for businesses looking for flexibility,
                simplified IT management, predictable monthly expenses, and
                automatic updates.
              </div>
              <div className="para3">
                <b>Security:</b> All your data is encrypted (both during
                transmission and while stored on our servers, guaranteeing its
                confidentiality). We never utilize your data for training our AI
                sub-processors, prioritizing your privacy.
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24}>
          <Row className="option-row">
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-img-col"
            >
              <img src={Option2Icon} alt="option2" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-col-2"
            >
              <div className="what-we-do-header">
                Option #2: Full License (On-premise) 
              </div>
              <div className="para3">
                For ultimate control and long-term cost predictability. This
                option gives you perpetual access to our software with a single
                upfront payment, eliminating recurring fees and token-based
                costs. You'll have the flexibility to install the software on
                your system, enabling offline accessibility whenever needed. 
                Perfect for businesses seeking long-term ownership, budget
                predictability, and offline accessibility.
              </div>
              <div className="para3">
                <b>Security:</b> You gain complete control over both the web
                application and the underlying AI language model. This ensures
                data residency within your environment and simplifies compliance
                with privacy regulations. 
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24}>
          <Row className="option-row">
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-img-col"
            >
              <img src={Option3Icon} alt="option3" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="option-col-2"
            >
              <div className="what-we-do-header">
                Option #3: Full License plus Ongoing Support:
              </div>
              <div className="para3">
                Combines the benefits of perpetual licensing with dedicated
                expert support. Enjoy a true partnership with our team provide
                ongoing guidance, maintenance, and proactive problem-solving to
                ensure your software remains optimized. We'll also empower your
                IT team through in-depth training and customized documentation,
                facilitating knowledge transfer and self-sufficiency.  Choose
                this option for a long-term technology partner dedicated to
                maximizing your software ROI and minimizing your IT burdens.
              </div>
              <div className="para3">
                <b>Security:</b> This model builds upon the data security
                benefits of the standalone license by adding an extra layer of
                proactive threat monitoring and mitigation from our expert
                team. 
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default OurStory;

const Wrapper = styled.div`
  padding: 30px 50px  100px 50px;
  @media (max-width: 768px) {
    padding: 50px 25px;
    width: 100%;
  }
  background-color: #f7f7f7;
  .cap-img {
    width: 100%;
    height: 43px;
    cursor: pointer;
    @media (max-width: 768px) {
      height: 43px;
    }
  }

  /* .our-story-btn {
    width: 100%;
    height: 43px;
    background-image: url(${CapBtnIcon});
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      height: 43px;
    }
  }

  .our-story-btn:hover {
    background-image: url(${FavIcon});
  } */
  .context-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    margin-top: 20px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: start;
      justify-content: center;
      align-items: center;
    }
  }
  .option-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-left: 40px;
    // margin-top: 40px;

    @media (max-width: 768px) {
      padding: 0 !important;
      text-align: center;
      justify-content: center;
      padding-left: 0;
      align-items: center;
    }
  }

  .what-we-do-header {
    width:100%;
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 46px;
        margin-top: 30px;
        text-align: start;
    }
    @media (max-width: 1200px) {
        margin-top: 60px;
    }
    }
  }

  .para1,
  .para2 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
    max-width: 550px;
  }

  .para3 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .btn {
    background-color: #00b840;
    color: #ffffff;
  }

  .img-col,
  .option-img-col {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .img-col img,
  .option-img-col img {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .option-img-col img {
    width: 90%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .option-row {
  display: flex;
  @media (max-width: 768px) {
    // flex-direction: column-reverse !important;
  }
}


 

  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
    .context-col-1,
    .option-col-2 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .para1,
    .para2,
    .para3 {
      text-align: start;
    }
    .option-row {
      // flex-direction: column-reverse;
      margin-top: -40px;
    }
    .option-img-col {
      margin-bottom: 0px;
    }
    .option-img-col img {
      width: 100%;
    }
    .option-col-2 {
      margin-top: 0px;
      padding: 0px ;
    }
    .context-col-1 {
      margin-top: 0px;
    }
    .investor-col {
      margin-top: -20px;
    }
  }

`;
