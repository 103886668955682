import { Col, CustomButton, Row } from "components/common";
import React from "react";
import styled from "styled-components";
import StoryIcon from "assets/images/story.png";
import WhyAIIcon from "assets/images/WhyAI.png";
import LeaderIcon from "assets/images/leader.png";
import InvesterIcon from "assets/images/investor.png";
import RegionalPartnersIcon from "assets/images/our-regional-partners.png";
import SoftwareTeamIcon from "assets/images/our-software-team.png";
import CapBtnIcon from "assets/svg/new-cap-btn.svg";
import CapDocumentIcon from "assets/svg/document.svg";
import FavIcon from "assets/images/favicon.png";

const OurStory = () => {
  const openPdf = (e, pdf) => {
    let pdfPath = "";

    if (pdf === "capability") {
      pdfPath = "/pdf/janus-capability-statement.pdf";
    } else if (pdf === "partner") {
      pdfPath = "/pdf/partner-program.pdf";
    }

    e.preventDefault();
    const newTab = window.open(pdfPath, "_blank");
    if (newTab) {
      newTab.onload = () => {
        const head = newTab.document.head;
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = FavIcon; // Use the correct path to your Janus favicon
        head.appendChild(link);
      };
    }
  };
  return (
    <Wrapper>
      <Row gutter={[0, 100]}>
        <Col xs={24} lg={24}>
          <Row gutter={[50, 0]}>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">Our Mission</div>
              <div className="para1">
                Janus's mission is to leverage technology to overcome critical
                bottlenecks across the global climate finance architecture.
              </div>
              <div className="para2">
                A major impediment to climate action is the capacity constraints
                of developing states — these constraints extend beyond funding
                and include the technical expertise needed to develop compelling
                project proposals that secure the confidence of the donor
                community.
              </div>
              <div className="para2">
                <strong style={{ fontWeight: 600 }}>
                  To address this, Janus leverages state-of-the-art software to
                  create user-friendly web applications that empower project
                  developers to access and unlock climate finance.
                </strong>
              </div>
              <div className="our-story-btn">
                <CustomButton
                  title={"Capability Statement"}
                  icon={
                    <img
                      src={CapDocumentIcon}
                      style={{ marginRight: "2px" }}
                      alt={"Capability Statement"}
                    />
                  }
                  className={"green"}
                  onClick={(e) => {
                    openPdf(e, "capability");
                  }}
                />
                {/*<img*/}
                {/*  src={CapBtnIcon}*/}
                {/*  className="cap-img"*/}
                {/*  onClick={(e) => {*/}
                {/*    openPdf(e);*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <img src={StoryIcon} alt="Our Story" />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24}>
          <Row className="leadership-row">
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="enabel-img-col"
            >
              <img src={LeaderIcon} alt="Our Leadership" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-2"
            >
              <div className="what-we-do-header">Our Leadership</div>
              <div className="para2">
                Oliver brings over 20 years of experience leading software
                programs and is a former Senior Research Fellow at the Center
                for Climate and Security and the Center for Strategic Risks.{" "}
                <a
                  className="view-linkedin"
                  href="https://www.linkedin.com/in/oliver-leighton-barrett-41095711/"
                  target="_blank"
                >
                  View LinkedIn
                </a>
              </div>
              <div className="para2">
                His deep understanding of climate finance dynamics and the
                challenges faced by small island developing states inspired him
                to bring together like-minded individuals to support the
                development of the world's first AI-powered software for climate
                project development and grant writing.
              </div>
              <div className="para2">
                Along with key investors, and a growing network of partners
                across the developing world, Janus is not only a first mover in
                bringing cutting edge technology to the climate finance space
                but is also building a global community of sustainability minded
                professionals to accelerate climate action.
              </div>
              <div className="button-container">
                <CustomButton
                  title={"Read Oliver’s Bio"}
                  className={"btn green"}
                  link={"/oliver-barrett"}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={24} className="investor-col">
          <Row gutter={[50, 0]}>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">Why AI and Why Now?</div>
              <div className="para1">
                Approximately 82% of companies globally are either actively
                using AI (40%) or exploring its adoption (42%), demonstrating
                widespread recognition of its transformative potential.
              </div>
              <div className="para2">
                No sector stands to benefit more from AI software than the
                climate finance ecosystem. Developing nations, which require
                substantial funding, often struggle to develop bankable project
                concepts and proposals.
              </div>
              <div className="para2">
                AI software can significantly enhance the capacity of these
                states to meet stringent funding criteria for adaptation and
                nature-based solutions, at a fraction of the traditional cost.
              </div>
              <div className="para2">
                Why wait? Reach out to the Janus team for a free consultation or
                trial.
              </div>
              <div className="button-container">
                <CustomButton
                  title={"Contact Us"}
                  className={"btn green"}
                  link={"/contact-us"}
                />
              </div>
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <img src={WhyAIIcon} alt="Why AI and Why Now?" />
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={24} className="investor-col">
          <Row gutter={[50, 0]}>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <img src={InvesterIcon} alt="Our Story" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">Our Investors</div>
              <div className="para1">
                Developing the world's first AI-powered software to remove the
                friction that currently afflicts both the demand and supply
                sides of the climate finance equation is a costly endeavor.
              </div>
              <div className="para2">
                Janus relies on the support of a small number of American and
                Caribbean philanthropists who believe in its mission to catalyze
                financial support for desperately needed mitigation and
                adaptation projects across the developing world.
              </div>
              <div className="para2">
                <strong style={{ fontWeight: 600 }}>
                  Our investors recognize the transformative potential of AI
                  technologies and believe in Janus Advisory's ability to
                  leverage these tools to address the urgent challenges of
                  climate change.
                </strong>
              </div>
              <div className="para2">
                They understand that investing in Janus and its mission is an
                investment in a more sustainable and equitable future for all.
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24} className="regional-partners-col">
          <Row gutter={[50, 0]}>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">Our Regional Partners</div>
              <div className="para1">
                Janus is committed to empowering organizations urgently needing
                project development support with AI-powered project development
                and review. To achieve this, we've partnered with a network of
                seasoned consultants who bring deep domain expertise to the
                table.
              </div>
              <div className="para2">
                These experts have undergone specialized training in our
                proprietary software, equipping them to leverage our teach in
                conjunction with their own knowledge and experience.
              </div>
              <div className="para2">
                <strong style={{ fontWeight: 600 }}>
                  This synergistic approach delivers exceptional results for our
                  clients, particularly those striving to secure funding for
                  critical climate-related initiatives.
                </strong>
              </div>
              <div className="para2">
                Every partner has a proven track record of success, supporting
                prominent organizations such as the World Bank, UNDP, GIZ,
                USAID, WWF, FAO, and GGGI, demonstrating their ability to
                advance climate action.
              </div>
              <div className="button-container">
                <CustomButton
                  title={"Become a Partner"}
                  className={"btn green"}
                  onClick={(e) => {
                    openPdf(e, "partner");
                  }}
                />
              </div>
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <img src={RegionalPartnersIcon} alt="Our Regional Partners" />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24} className="our-team-col">
          <Row gutter={[50, 0]}>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <img src={SoftwareTeamIcon} alt="Our Software Team" />
            </Col>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">Our Software Team</div>
              <div className="para1">
                The Janus technical team has not only developed the world's
                first AI-powered software kit that empowers climate project
                developers to create winning project proposals, but has also
                built the competencies to design and construct high-quality web
                applications to support all types of sustainability use cases.
              </div>
              <div className="para2">
                The team is comprised of:
                <br />
                <br />
                <ul>
                  <li>Project Leads</li>
                  <li>AI Engineers</li>
                  <li>UX/UI Designers</li>
                  <li>Front-End and Back-End Developers</li>
                  <li>Quality Assurance Testers</li>
                </ul>
              </div>
              <div className="para2">
                Each with deep experience in their respective fields. Their
                passion for innovation and commitment to excellence ensures that
                Janus consistently delivers solutions that are both powerful and
                user-friendly.
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default OurStory;

const Wrapper = styled.div`
  padding: 100px 50px;
  @media (max-width: 768px) {
    padding: 50px 25px;
    width: 100%;
  }
  background-color: #f7f7f7;

  /* .our-story-btn {
    width: 100%;
    height: 43px;
    background-image: url(${CapBtnIcon});
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      height: 43px;
    }
  }

  .our-story-btn:hover {
    background-image: url(${FavIcon});
  } */
  .context-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    margin-top: 20px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  .context-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-left: 40px;
    margin-top: 40px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      justify-content: center;
      padding-left: 0;
      align-items: center;
    }
  }

  .what-we-do-header {
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  .para1,
  .para2 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
    max-width: 550px;
  }

  .para3 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .btn {
    background-color: #00b840;
    color: #ffffff;
  }

  .view-linkedin {
    text-decoration: underline;
    color: #00b840;
  }

  .img-col,
  .enabel-img-col {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    //padding: 50px !important;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .img-col img,
  .enabel-img-col img {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .enabel-img-col img {
    width: 90%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .leadership-row {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .button-container {
    @media (max-width: 768px) {
    }
  }

  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
    .context-col-1,
    .context-col-2 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .para1,
    .para2,
    .para3 {
      text-align: center;
    }
    .leadership-row {
      flex-direction: column-reverse;
      margin-top: -40px;
    }
    .enabel-img-col {
      margin-bottom: 0px;
    }
    .enabel-img-col img {
      width: 100%;
    }
    .context-col-2 {
      margin-top: 0px;
      padding: 0px 25px;
    }
    .context-col-1 {
      margin-top: 0px;
    }
    .investor-col {
      margin-top: -20px;
    }
    .regional-partners-col {
      margin-top: -20px;
    }
  }

  @media (max-width: 500px) {
    .button-container {
      width: 100%;
    }
    .our-story-btn {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
`;
