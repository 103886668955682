import React from "react";
import styled from "styled-components";
import { Collapse } from "antd";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as PlusIcon } from "assets/svg/plus.svg";

const { Panel } = Collapse;

const PricingFaqCollapse = () => {
  const customExpandIcon = ({ isActive }) =>
    isActive ? (
      <CloseIcon className="faq-icon" />
    ) : (
      <PlusIcon className="faq-icon" />
    );

  return (
    <StyledCollapse expandIconPosition="left" expandIcon={customExpandIcon}>
      <Panel
        header="Why should I invest in the Climate Finance Copilot?"
        key="1"
      >
        <p>
          The Climate Finance Copilot is a strategic investment in your project
          pipeline future. By leveraging its advanced features, you can:
        </p>
        <ul>
          <li>
            <strong>Save Valuable Time:</strong> Semi-automate repetitive tasks
            in the project preparation cycle, freeing up hundreds of hours
            annually.
          </li>
          <li>
            <strong>Increase Approval Likelihood:</strong> Submit stronger, more
            competitive proposals, significantly improving your chances of
            securing funding.
          </li>
          <li>
            <strong>Avoid Lost Opportunities:</strong> Don't let inadequate
            proposal development jeopardize your project's potential.
          </li>
        </ul>
      </Panel>

      <Panel
        className="margin-panel"
        header="Is my data safe when using No-Objection Assistant?"
        key="2"
      >
        <p>
          Yes, your data is encrypted both in transit and at rest. Here's a
          breakdown based on hosting:
        </p>
        <ul>
          <li>
            <strong>Janus-Hosted (Our Infrastructure):</strong> Your data is
            encrypted in transit and at rest on our secure servers. We can also
            accommodate regional preferences for data storage.
          </li>
          <li>
            <strong>Self-Hosted (Your Infrastructure):</strong> Your data is
            stored on your servers, and you are responsible for its security.
            This provides the most direct control over your data.
          </li>
          <li>
            <strong>AI Processing (Sub-processors):</strong> We currently use
            US-based commercial language model providers for AI processing.
            These providers do not use your data for training purposes, and your
            data is encrypted in transit. For maximum security, you can
            self-host an AI model and purchase a perpetual license.
          </li>
        </ul>
      </Panel>

      <Panel
        className="margin-panel"
        header="What is the monthly cost for CFC Enterprise?"
        key="3"
      >
        <p>
          Beyond the annual license cost which includes up to three users, each
          additional user will cost <strong>$500/month</strong> to access the
          software.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="How long is the mandatory contract duration for CFC Enterprise?"
        key="4"
      >
        <p>
          CFC requires a <strong>1-year contract</strong>.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="Does the pricing include add-on services?"
        key="5"
      >
        <p>
          No, the pricing only covers the ERP Cloud Service. Additional services
          will incur extra costs.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="What should I consider when licensing CFC Enterprise?"
        key="6"
      >
        <p>
          Ensure you account for all users with access, not just active users,
          to stay compliant.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="Is there a discount available for larger user numbers?"
        key="7"
      >
        <p>
          Discounts may be available for larger numbers of users, but specific
          details would depend on negotiations with Janus.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="What happens if my user count changes?"
        key="8"
      >
        <p>
          To stay compliant and optimize costs, you would need to adjust your
          licensing to reflect the actual number of users with access.
        </p>
      </Panel>
    </StyledCollapse>
  );
};

export default PricingFaqCollapse;

const StyledCollapse = styled(Collapse)`
  .margin-panel {
    padding-top: 20px;
  }

  .ant-collapse-header {
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #000000;
    border: none !important;
    line-height: 32px;
  }

  .ant-collapse-content {
    font-weight: 400;
    font-size: 20px;
    background-color: #fff !important;
    border: none !important;
    line-height: 32px;
  }

  .ant-collapse-item {
    border: none !important;
  }

  .faq-icon {
    color: #00b840;
    margin-right: 8px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 60px;
    padding-right: 45px;
  }

  p {
    margin-bottom: 0px !important;
    color: #2e2e2e;
  }
  .price {
    color: #00b840;
    cursor: pointer;
  }
  .para2 {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px 0px;
    }

    .ant-collapse-header {
      font-size: 14px;
    }

    .ant-collapse-content {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 992px) {
    .ant-collapse-content > .ant-collapse-content-box {
      padding-right: 0px;
      padding-left: 42px;
    }
  }
`;
