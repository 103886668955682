import React from "react";
import styled from "styled-components";
import { Col, CustomButton, Row } from "../../../components/common";
import PriceCard from "./PriceCard";

const ProgramCard = ({ id, programName, ...props }) => {
  return (
    <Wrapper
      style={
        id % 2 == 0 ? { background: "#FFFFFF" } : { background: "#F7F7F7" }
      }
    >
      <Row gutter={[0, 100]}>
        <Col xs={24} lg={24} className="no-cost-col">
          <Row gutter={[50, 0]}>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              {props.children}
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <PriceCard programName={programName} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ProgramCard;

const Wrapper = styled.div`
  padding: 100px 50px;
  @media (max-width: 768px) {
    padding: 50px 25px;
    width: 100%;
  }
  background-color: #f7f7f7;

  .context-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    margin-top: 20px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  .btn {
    background-color: #00b840;
    color: #ffffff;
  }

  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-bottom: 20px;
    //padding: 50px !important;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
    .context-col-1 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .para1,
    .para2,
    .para3 {
      text-align: center;
    }

    .context-col-1 {
      margin-top: 0px;
    }
  }

  @media (max-width: 500px) {
  }
`;
