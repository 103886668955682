import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

const PageName = styled.h1`
  font-size: 72px;
  font-weight: 600;
  color: #000000;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 50px;
    text-align: center;
  }
`;

const PageMetaName = styled.span`
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  display: block;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  padding: 20px 50px 50px 50px;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;

  //padding: 40px 80px;
  //max-width: 1200px;
  //margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }
`;

const CustomSection = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  img {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
  }
`;

const DynamicPageName = ({
  pageName,
  className,
  pageMetaName,
  style,
  image,
  customContent,
}) => {
  return (
    <Wrapper style={style}>
      <Row gutter={[32, 0]}>
        {/* Left Column: Title and Content */}
        <Col xs={24} md={12}>
          {pageMetaName && <PageMetaName>{pageMetaName}</PageMetaName>}
          <PageName className={className}>{pageName}</PageName>
          {customContent && <CustomSection>{customContent}</CustomSection>}
        </Col>

        {/* Right Column: Image */}
        <Col xs={24} md={12}>
          {image && <ImageWrapper>{image}</ImageWrapper>}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default DynamicPageName;
