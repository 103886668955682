import React from "react";
import styled from "styled-components";
import TitleComponent from "../../components/title-component/TitleComponent";
import Introduction from "./section/Introduction";
import PartnerList from "./section/PartnerList";
import PageHeadingImage from "../../assets/images/partner-heading.jpg";
import { CustomButton } from "components/common";

const PageContent = () => {
  return (
    <Wrapper>
      <TitleComponent
        pageMetaName="Partner Program"
        className="page-heading"
        image={
          <img
            src={PageHeadingImage}
            alt="Partner Program"
            className="page-heading-image"
          />
        }
        pageName={
          <>
            Join Us In <br /> Expanding <br /> Climate Finance
          </>
        }
        customContent={
          <>
            <p className="page-heading-custom-content">
              Leverage our state-of-the-art software to generate significant
              income and deliver outstanding results to organizations globally.
              You'll assist project developers worldwide in enhancing the
              quality of their proposals resulting in greater climate impact.
            </p>
            <CustomButton
              title={"Contact Us"}
              className={"btn green"}
              link={"/contact-us"}
            />
          </>
        }
      />
      <Introduction />
      <PartnerList />
    </Wrapper>
  );
};

export default PageContent;

const Wrapper = styled.div`
  .row-header {
    color: #000000;
    font-size: 32px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  ul > li {
    padding: 10px 0;
  }

  ul {
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  .btn {
    background-color: #00b840;
    color: #ffffff;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .page-heading-custom-content {
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 400;

    @media (max-width: 768px) {
      text-align: left;
    }
  }

  .page-heading-image {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .page-heading {
    //max-width: 50%;
    //@media (max-width: 768px) {
    //  max-width: unset;
    //}
  }
`;
