import React from "react";
import styled from "styled-components";

const Introduction = () => {
  return (
    <Wrapper>
      <p>
        Don't risk losing millions in support because of a weak proposal. Reach
        out and we’ll put you in touch with the respective regional partner for
        your AI aided review.
      </p>
    </Wrapper>
  );
};

export default Introduction;

const Wrapper = styled.div`
  padding: 0 50px 50px 50px;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  color: #2e2e2e;
  font-weight: 400;
  margin-top: -40px;

  @media (max-width: 768px) {
    margin-top: -10px;
  }

  max-width: 50%;
  @media (max-width: 1024px) {
    text-align: center;
    max-width: unset;
  }
`;
