import React from "react";
import { Card, Button, Typography, Row, Col } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FavIcon from "../../../assets/images/favicon.png";
import ConceptNoteProgram from "assets/images/concept-note-program.png";
import FundingProposalProgram from "assets/images/funding-proposal-program.png";
import AdaptationFundProposalProgram from "assets/images/adaptation-fund-proposal-program.png";
import VCSProposalProgram from "assets/images/vcs-proposal-program.png";

const { Title, Text } = Typography;

const reportFileNameDict = {
  "Concept Note Evaluation": {
    report: "/pdf/concept-note.pdf",
    img: ConceptNoteProgram,
    price: "$1,999 - $2,999 USD",
  },
  "Funding Proposal Evaluation": {
    report: "/pdf/funding-proposal.pdf",
    img: FundingProposalProgram,
    price: "$4,999 - $9,999 USD",
  },
  "Adaptation Fund Proposal Review": {
    report: "/pdf/adaptation-fund.pdf",
    img: AdaptationFundProposalProgram,
    price: "$1,999 - $4,999 USD",
  },
  "VCS Proposal Review": {
    report: "/pdf/vcs-proposal.pdf",
    img: VCSProposalProgram,
    price: "$7,999 - $9,999 USD",
  },
};

const PricingCard = ({ programName, programPrice }) => {
  const nav = useNavigate();

  const openPdf = (e, programName) => {
    e.preventDefault();
    const newTab = window.open(
      reportFileNameDict[programName].report,
      "_blank",
    );
    if (newTab) {
      newTab.onload = () => {
        const head = newTab.document.head;
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = FavIcon; // Use the correct path to your Janus favicon
        head.appendChild(link);
      };
    }
  };

  const reportAvailable = reportFileNameDict[programName].report !== undefined;

  return (
    <Wrapper>
      <Card className="price-card">
        <img
          src={reportFileNameDict[programName]?.img}
          alt={programName}
          className="img-fluid"
        />
        <Title level={2}>{reportFileNameDict[programName]?.price}</Title>
        <Text className="card-description">
          If you have any questions about the pricing or would like to learn
          more about the service, contact us.
        </Text>
        <Row gutter={16} justify="center">
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={() => nav("/contact-us")}
              style={{
                backgroundColor: "#00B840",
                borderColor: "#00B840",
                borderRadius: "5px",
              }}
            >
              Contact Us
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              style={
                reportAvailable
                  ? {
                      borderColor: "#00B840",
                      color: "#00B840",
                      borderRadius: "5px",
                    }
                  : {
                      borderColor: "#00B840",
                      color: "#00B840",
                      borderRadius: "5px",
                      opacity: "0.3",
                      cursor: "not-allowed",
                    }
              }
              onClick={reportAvailable ? (e) => openPdf(e, programName) : null}
            >
              Sample Report
            </Button>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .price-card {
    max-width: 600px;
    //max-height: 600px;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-description {
    margin-bottom: 20px;
    color: #555;
    display: block;
  }

  .img-fluid {
    //width: 100%;
    width: 255px;
    border-radius: 5px;
    margin-bottom: 16px;
  }
`;

export default PricingCard;
