import React from "react";
import styled from "styled-components";
import Banner from "./section/Banner";
import PricingForAEs from "./section/Introduction";
import PricingForAEsCardSection from "./section/CardSection";
import PricingForAEsImages from "./section/HowToFund";
import FAQ from "./section/FAQ";

const PageContent = () => {
  return (
    <Wrapper>
      <Banner />
      <div className="PricingForAEs-container">
        <PricingForAEs />
        <PricingForAEsCardSection />
        <PricingForAEsImages />
        <FAQ />
      </div>
    </Wrapper>
  );
};

export default PageContent;

const Wrapper = styled.div`
  .PricingForAEs-container {
    background-color: #f7f7f7;
  }
`;
