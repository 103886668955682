import React from "react";
import styled from "styled-components";
import { Col, CustomButton, Row } from "../../../components/common";
import GCFLogo from "../../../assets/images/gcf-logo.png";
import AFLogo from "../../../assets/images/af-logo.jpeg";
import GEFLogo from "../../../assets/images/gef-logo.png";

const NoCost = () => {
  return (
    <Wrapper>
      <Row gutter={[0, 100]}>
        <Col xs={24} lg={24} className="no-cost-col">
          <Row gutter={[50, 0]}>
            <Col
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="context-col-1"
            >
              <div className="what-we-do-header">No Cost to You!</div>
              <div className="para1">
                Our software is available as a service and qualifies as a
                technical assistance or readiness-enabling activity.{" "}
                <strong style={{ fontWeight: 600 }}>
                  This means you can use readiness grants from multinational
                  donors like the Green Climate Fund to cover the initial setup
                  and training.
                </strong>
              </div>
              <div className="para2">
                Funding mechanisms include:
                <br />
                <br />
                <ul>
                  <li>
                    Direct Access Window: This window supports entities in
                    strengthening their capacity to directly access GCF funding.
                  </li>
                  <br />
                  <li>
                    Project Preparation Facility: This facility provides funding
                    for developing high-quality concept notes and proposals.
                  </li>
                  <br />
                  <li>
                    Readiness Grants: These grants support a wide range of
                    readiness activities, including strengthening institutional
                    capacity and developing in-house expertise.
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="img-col">
              <Row
                gutter={[0, 24]}
                justify="center"
                align="middle"
                className="img-row"
              >
                <img src={AFLogo} alt="AF Logo" className="logo-img" />
                <img src={GEFLogo} alt="GEF Logo" className="logo-img" />
              </Row>
              <Row
                gutter={[0, 24]}
                justify="center"
                align="middle"
                className="img-row"
              >
                <img src={GCFLogo} alt="GCF Logo" className="logo-img" />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default NoCost;

const Wrapper = styled.div`
  padding: 100px 50px;
  @media (max-width: 768px) {
    padding: 50px 25px;
    width: 100%;
  }

  //padding-top: 90px;
  background-color: #fff;

  .img-row {
    display: flex;
    align-items: center;
  }

  .logo-img {
    max-width: 300px;
    height: auto;
    width: 100%;
  }

  .context-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    margin-top: 20px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  .context-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-left: 40px;
    margin-top: 40px;

    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      justify-content: center;
      padding-left: 0;
      align-items: center;
    }
  }

  .what-we-do-header {
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  .para1,
  .para2 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
    max-width: 550px;
  }

  .para3 {
    margin-top: 10px;
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .btn {
    background-color: #00b840;
    color: #ffffff;
  }

  .img-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ffffff;

    margin-bottom: 20px;
    //padding: 50px !important;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .img-col img,
  .enabel-img-col img {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .enabel-img-col img {
    width: 90%;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .leadership-row {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .button-container {
    @media (max-width: 768px) {
    }
  }

  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
    .context-col-1,
    .context-col-2 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .para1,
    .para2,
    .para3 {
      text-align: center;
    }
    .leadership-row {
      flex-direction: column-reverse;
      margin-top: -40px;
    }
    .enabel-img-col {
      margin-bottom: 0px;
    }
    .enabel-img-col img {
      width: 100%;
    }
    .context-col-2 {
      margin-top: 0px;
      padding: 0px 25px;
    }
    .context-col-1 {
      margin-top: 0px;
    }
    .investor-col {
      margin-top: -20px;
    }
    .regional-partners-col {
      margin-top: -20px;
    }
  }

  @media (max-width: 500px) {
    .button-container {
      width: 100%;
    }
    .our-story-btn {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
`;
