import React, { useEffect, useRef } from "react";
import { Row, Col } from "components/common";
import styled from "styled-components";
import PricingForAEsCards from "./Cards";

const PricingForAEsCardsSection = () => {
  return (
    <Wrapper>
      <Row gutter={[30, 30]}>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <PricingForAEsCards
            className="teaching-card"
            title={"Pricing Structure (Janus Cloud)"}
            desc={
              <>
                <ul>
                  <li>Set-up fee: $5,000 - $7,000*</li>
                  <li>Annual license: $25,000</li>
                  <li>Monthly Cost: $500 (extra per user)</li>
                </ul>
              </>
            }
            subtext="*Cost could be higher based upon the level of customization requested."
          />
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <PricingForAEsCards
            className="teaching-card"
            title={"Pricing Structure (Your infrastructure)"}
            desc={
              <>
                <ul>
                  <li>Set-up fee: $5,000 - $15,000*</li>
                  <li>Annual license: $20,000</li>
                  <li>Monthly Cost: $500 (extra per user)</li>
                </ul>
              </>
            }
            subtext="*Cost will vary depending upon the level of support requested by the Janus team.
        Additionally, client may opt to self host an AI model, this can be accommodated."
          />
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <PricingForAEsCards
            className="teaching-card"
            title={"Contract Duration"}
            desc={
              <>
                <ul>
                  <li>
                    Janus requires a minimum one-year contract with discounts
                    offered for multi-year agreements.
                  </li>
                  <li>
                    A Service Level Agreement (SLA) which guarantees up-time,
                    response times, and other performance metrics is included
                    with every contract.
                  </li>
                </ul>
              </>
            }
          />
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <PricingForAEsCards
            className="teaching-card"
            title={"Additional Costs"}
            desc={
              <>
                <ul>
                  <li>
                    Some clients may opt for a tech transfer or perpetual
                    license options. Janus negotiates these on a case-by-case
                    basis.
                  </li>
                  <li>
                    We recommend that all perpetual license clients purchase a
                    service plan ($6,000/year) to ensure access to the latest
                    releases and technical support.
                  </li>
                </ul>
              </>
            }
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default PricingForAEsCardsSection;

const Wrapper = styled.div`
  padding: 10px 50px 50px 50px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-10px);
  }
  @media (max-width: 768px) {
    padding: 10px 25px 45px 25px;
  }
`;
