import React from "react";
import styled from "styled-components";
import { Collapse } from "antd";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as PlusIcon } from "assets/svg/plus.svg";

const { Panel } = Collapse;

const PricingFaqCollapse = () => {
  const customExpandIcon = ({ isActive }) =>
    isActive ? (
      <CloseIcon className="faq-icon" />
    ) : (
      <PlusIcon className="faq-icon" />
    );

  return (
    <StyledCollapse expandIconPosition="left" expandIcon={customExpandIcon}>
      <Panel
        header="Why should I invest in the No-Objection Assistant?"
        key="1"
      >
        <p>
          Because you'll save hundreds of hours annually by automating your
          national no-objection reviews. Powered by cutting-edge AI language
          models, this software quickly and accurately assesses projects against
          your criteria. This significantly accelerates the review process and
          ensures the NDA is never a roadblock to project progress, freeing you
          to focus on higher-priority tasks.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="How does No-Objection Assistant work?"
        key="2"
      >
        <p>
          This Assistant is powered by the AI language model (selectable in the
          model menu) which reviews and assesses the narratives you provide it
          against a pre-set criterion. The language models and prompts used are
          carefully configured and tested to ensure optimum results.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="Is my data safe when using No-Objection Assistant?"
        key="3"
      >
        <p>
          Yes, your data is encrypted both in transit and at rest. Here's a
          breakdown based on hosting:
        </p>
        <ul>
          <li>
            <strong>Janus-Hosted (Our Infrastructure):</strong> Your data is
            encrypted in transit and at rest on our secure servers. We can also
            accommodate regional preferences for data storage.
          </li>
          <li>
            <strong>Self-Hosted (Your Infrastructure):</strong> Your data is
            stored on your servers, and you are responsible for its security.
            This provides the most direct control over your data.
          </li>
          <li>
            <strong>AI Processing (Sub-processors):</strong> We currently use
            US-based commercial language model providers for AI processing.
            These providers do not use your data for training purposes, and your
            data is encrypted in transit. For maximum security, you can
            self-host an AI model and purchase a perpetual license.
          </li>
        </ul>
      </Panel>

      <Panel
        className="margin-panel"
        header="What is the cost for No-Objection Assistant?"
        key="4"
      >
        <p>
          <ul>
            <li>
              Janus hosted annual license: <strong>$15,000</strong>
            </li>
            <li>
              Self-hosted annual license: <strong>$10,000</strong>
            </li>
            <li>
              Monthly cost: <strong>$300</strong> (extra per user)
            </li>
          </ul>
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="How long is the mandatory contract duration?"
        key="5"
      >
        <p>
          Janus requires a minimum one-year contract with discounts offered for
          multi-year agreements.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="Does the pricing include add-on services?"
        key="6"
      >
        <p>
          No, the pricing only covers the No-Objection Assistant. Additional
          services such as assistance in installing a local language model
          capability will incur extra costs.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="What should I consider when licensing No-Objection Assistant?"
        key="7"
      >
        <p>
          The Assistant is very easy to operate and maintain. From time to time
          you may need to update the criteria that is being fed to the web app.
          This is easy to accomplish via the Master prompt panel.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="Is there a discount available for larger user numbers?"
        key="8"
      >
        <p>
          No, generally, discounts are available only for multi-year licenses.
        </p>
      </Panel>

      <Panel
        className="margin-panel"
        header="What happens if the number of users changes?"
        key="9"
      >
        <p>
          To stay compliant and optimize costs, you would need to adjust your
          licensing to reflect the actual number of users with access.
        </p>
      </Panel>
    </StyledCollapse>
  );
};

export default PricingFaqCollapse;

const StyledCollapse = styled(Collapse)`
  .margin-panel {
    padding-top: 20px;
  }

  .ant-collapse-header {
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #000000;
    border: none !important;
    line-height: 32px;
  }

  .ant-collapse-content {
    font-weight: 400;
    font-size: 20px;
    background-color: #fff !important;
    border: none !important;
    line-height: 32px;
  }

  .ant-collapse-item {
    border: none !important;
  }

  .faq-icon {
    color: #00b840;
    margin-right: 8px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 60px;
    padding-right: 45px;
  }

  p {
    margin-bottom: 0px !important;
    color: #2e2e2e;
  }
  .price {
    color: #00b840;
    cursor: pointer;
  }
  .para2 {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px 0px;
    }

    .ant-collapse-header {
      font-size: 14px;
    }

    .ant-collapse-content {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 992px) {
    .ant-collapse-content > .ant-collapse-content-box {
      padding-right: 0px;
      padding-left: 42px;
    }
  }
`;
