import React from "react";
import styled from "styled-components";
import { Col, Row } from "../../../components/common";

const PartnerRow = ({ id, img, reverse = false, ...props }) => {
  return (
    <Wrapper reverse={reverse}>
      <Row
        gutter={[0, 100]}
        className={`partner-row ${reverse ? "reverse" : ""}`}
      >
        {/* Image Column */}
        <Col
          xs={24}
          md={8}
          lg={7}
          xl={10}
          className={`img-col ${id === 4 ? "borderless" : ""}`}
        >
          {img}
        </Col>

        {/* White Space (15%) */}
        <Col xs={0} md={2} lg={2} xl={2} />

        {/* Text Column */}
        <Col xs={24} md={14} lg={15} xl={12} className="context-col-1">
          {props.children}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default PartnerRow;

const Wrapper = styled.div`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 18px;
  }

  margin-bottom: 100px;

  .partner-row {
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .context-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    @media (max-width: 768px) {
      text-align: left;
      //align-items: center;
    }
  }

  .img-col {
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: #f7f7f7;
    //min-height: 500px;
  }

  .img-col.borderless {
    background-color: unset;
    align-items: center;
    justify-content: center;
  }

  .img-col.borderless > .partner-img {
    max-width: 600px;
  }

  .partner-img {
    width: 100%;
    max-width: 350px;
    height: auto;

    @media (max-width: 768px) {
      min-width: unset !important;
    }
  }
`;
