import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import AuthLayout from "layouts/auth/AuthLayout";
import HomePage from "views/home/PageContent";
import AboutUsPage from "views/about-us/PageContent";
import ClimateFinancePage from "views/climate-finance/PageContent";
import OurTeamPage from "views/our-team/PageContent";
import LicensingModelsPage from "views/licensing-models/PageContent";
import ProposalReviews from "views/proposal-reviews/PageContent";
import PartnerProgram from "views/partner-program/PageContent";
import ContactUsPage from "views/contact-us/PageContent";
import RequestADemoPage from "views/request-a-demo/PageContent";
import FaqsPage from "views/faqs/PageContent";
import ProcurementPage from "views/procurement/PageContent";
import SuccessStoriesPage from "views/success-stories/PageContent";
import OliverBarrettPage from "views/oliver-barrett/PageContent";
import PricingForNDAs from "views/pricing-for-ndas/PageContent";
import PricingForAEs from "views/pricing-for-accredited-entities/PageContent";

// Define your routes using the data router API
const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />, // Layout is shared across routes
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/about-us", element: <AboutUsPage /> },
      { path: "/climate-finance-copilot", element: <ClimateFinancePage /> },
      { path: "/our-team", element: <OurTeamPage /> },
      { path: "/licensing-models", element: <LicensingModelsPage /> },
      { path: "/for-ndas", element: <PricingForNDAs /> },
      { path: "/for-accredited-entities", element: <PricingForAEs /> },
      { path: "/proposal-reviews", element: <ProposalReviews /> },
      { path: "/partner-program", element: <PartnerProgram /> },
      { path: "/contact-us", element: <ContactUsPage /> },
      { path: "/request-a-demo", element: <RequestADemoPage /> },
      { path: "/faqs", element: <FaqsPage /> },
      { path: "/procurement", element: <ProcurementPage /> },
      { path: "/success-stories", element: <SuccessStoriesPage /> },
      { path: "/oliver-barrett", element: <OliverBarrettPage /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
]);

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}
