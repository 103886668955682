import React, { useEffect } from "react";
import { Outlet, useLocation, ScrollRestoration } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import Footer from "./Footer";
import GreenHeader from "./GreenHeader";
import WhiteHeader from "./WhiteHeader";
import LightGreenHeader from "./LightGreenHeader";

// Define global styles
const GlobalStyle = createGlobalStyle`

`;

// Define route-specific header configurations
const headerConfig = {
  "/": { showGreenHeader: true, headerTheme: "green" },
  "/about-us": { showWhiteHeader: true },
  "/our-team": { showWhiteHeader: true },
  "/climate-finance-copilot": { showLightGreenHeader: true },
  "/licensing-models": { showWhiteHeader: true },
  "/proposal-reviews": { showWhiteHeader: true },
  "/partner-program": { showWhiteHeader: true },
  "/contact-us": { showWhiteHeader: true },
  "/request-a-demo": { showWhiteHeader: true },
  "/faqs": { showWhiteHeader: true },
  "/procurement": { showGreenHeader: true, headerTheme: "seagreen" },
  "/for-ndas": { showGreenHeader: true, headerTheme: "darkgreen" },
  "/for-accredited-entities": {
    showGreenHeader: true,
    headerTheme: "darkergreen",
  },
  "/success-stories": { showWhiteHeader: true },
  "/oliver-barrett": { showWhiteHeader: true },
};

export default function AuthLayout() {
  const location = useLocation();

  useEffect(() => {
    console.log("have to scroll");

    // Delay scroll reset to ensure DOM updates first
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 50);

    // Alternative approach: requestAnimationFrame (smoother)
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, [location.pathname]);

  // Determine header props based on current path
  const {
    showGreenHeader,
    showWhiteHeader,
    showLightGreenHeader,
    headerTheme,
  } = headerConfig[location.pathname] || {}; // Default to empty object if path not found

  return (
    <>
      <GlobalStyle />
      {/*<ScrollRestoration />*/}
      <header>
        {showGreenHeader && <GreenHeader headerTheme={headerTheme} />}
        {showWhiteHeader && <WhiteHeader />}
        {showLightGreenHeader && <LightGreenHeader />}
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
