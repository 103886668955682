import React from "react";
import styled from "styled-components";
import Banner from "./section/Banner";
import PricingForNDAs from "./section/Introduction";
import PricingForNDAsCardSection from "./section/CardSection";
import PricingForNDAsImages from "./section/HowToFund";
import FAQ from "./section/FAQ";

const PageContent = () => {
  return (
    <Wrapper>
      <Banner />
      <div className="PricingForNDAs-container">
        <PricingForNDAs />
        <PricingForNDAsCardSection />
        <PricingForNDAsImages />
        <FAQ />
      </div>
    </Wrapper>
  );
};

export default PageContent;

const Wrapper = styled.div`
  .PricingForNDAs-container {
    background-color: #f7f7f7;
  }
`;
