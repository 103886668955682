import React, { useEffect } from "react";
import styled from "styled-components";
import JanusBlackIcon from "assets/svg/Janus-logo-black.svg";
import LinkedIn from "assets/svg/linked-in.svg";
import Youtube from "assets/svg/you-tube.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Wrapper>
      <div className="footer-content">
        <div className="brand">
          <Link to="/">
            <img src={JanusBlackIcon} className="janus-icon" alt="Janus Logo" />
          </Link>
          <p className="description">
            An AI-oriented consultancy specializing in creating custom software
            solutions for enterprises across the developing world.
          </p>
        </div>
        <div className="links-section">
          <div className="links-column">
            <h3 className="column-title">Company</h3>
            <Link to="/about-us" className="link-item">
              About Us
            </Link>
          </div>
          <div className="links-column">
            <h3 className="column-title">Solutions</h3>
            <Link to="/climate-finance-copilot" className="link-item">
              Climate Finance Copilot
            </Link>
            <Link to="/proposal-reviews" className="link-item">
              Proposal Reviews
            </Link>
            <Link to="/procurement" className="link-item">
              Procurement
            </Link>
          </div>
          <div className="links-column">
            <h3 className="column-title">Licensing</h3>
            <Link to="/for-ndas" className="link-item">
              For NDAs
            </Link>
            <Link to="/for-accredited-entities" className="link-item">
              For Accredited Entities
            </Link>
            <Link to="/licensing-models" className="link-item">
              Licensing Models
            </Link>
          </div>
          <div className="links-column">
            <h3 className="column-title">Partners</h3>
            <Link to="/partner-program" className="link-item">
              Partner Program
            </Link>
          </div>
          <div className="links-column">
            <h3 className="column-title">Resources</h3>
            <Link to="/faqs" className="link-item">
              FAQs
            </Link>
          </div>
        </div>
      </div>
      <hr className="hr-line" />
      <div className="bottom-bar">
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/janus-advisory-services/"
            className="social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} className="social-icon" alt="LinkedIn" />
          </a>
          <a
            href="https://www.youtube.com/@JanusAdvisoryAdmin"
            className="social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Youtube} className="social-icon" alt="YouTube" />
          </a>
        </div>
        <div className="legal-and-copyright">
          <div className="copyright">© 2024 Janus. All rights reserved.</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px 50px;

  @media (max-width: 810px) {
    padding: 40px 25px;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;

    @media (max-width: 810px) {
      flex-direction: column;
      align-items: start;
    }
  }

  .hr-line {
    margin-top: 15px;
  }

  .brand {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
  }

  .janus-icon {
    max-width: 120px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .description {
    color: #666;
    margin-bottom: 1em;
    margin-top: 10px;
    width: 230px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: 576px) {
      width: 100%;
    }
    @media (max-width: 810px) {
      width: 380px;
    }
    @media (max-width: 380px) {
      max-width: 340px;
    }
  }

  .links-section {
    display: flex;
    justify-content: flex-start;
    flex: 3;
    min-width: 200px;
    gap: 50px;

    @media (max-width: 992px) {
      gap: 50px;
      min-width: 100px;
    }

    @media (max-width: 810px) {
      flex-direction: column;
      align-items: start;
      text-align: start;
      gap: 10px;
    }
  }

  .links-column {
    min-width: 95px;
    margin-bottom: 20px;
  }

  .column-title {
    margin-bottom: 0.3rem;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: 600;
  }

  .link-item {
    width: 180px;
    display: block;
    color: #2e2e2e;
    margin-bottom: 8px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 810px) {
      width: 100%;
      padding: 0.5rem 0;
    }
    //@media (max-width: 1160px) {
    //  width: 115px;
    //}
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 0;
    padding-top: 5px;

    @media (max-width: 810px) {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      gap: 15px;
    }
  }

  .social-links {
    display: flex;
    gap: 10px;

    @media (max-width: 810px) {
      margin-bottom: 10px;
    }
  }

  .social-link {
    cursor: pointer;
    margin-right: 5px;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .copyright {
    color: #2e2e2e;
    font-size: 12px;
    font-weight: 400;

    @media (max-width: 810px) {
      margin-top: 10px;
    }
  }
`;
